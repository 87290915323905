import styled from 'styled-components';



export const TableComponentStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-collapse: collapse;
`;
