import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  ValidateAddressCompany,
} from '@noitada/shared/utils/company.utils';

import {
  textOrEmpty,
  validateNumber,
  validateString,
} from '@noitada/shared/utils/string.utils';

import {
  openSite,
  openUber,
  openWaze,
} from '../../config/linking.config';

import {
  GetDeviceType,
} from '../../config/plataform.config';

import {
  SocialButton,
  SubtitleText,
} from '../../styles/styled.layout';

import ButtonSocial from '../Buttons/ButtonSocial';

import {
  Mask,
} from '../Composh/plugins';

import CopiedItem from '../Contents/CopiedItem';

import {
  IconCopy,
} from '../Icons';

import MapGoogle from '../Maps/Map';
import MapMarker from '../Maps/MapAppMarker';

import {
  CompanyMapView,
  CompanySubtitleMap,
  MapViewStyle,
  DirectionsView,
  PlaceAddressView,
  TextAddressView,
} from './styled';



export interface IProps {
  companyName: string;
  address: any;
  noWaze?: boolean;
  noUber?: boolean;
}



const DetailsAddress: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const [copied, setCopied] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  const addressValid = ValidateAddressCompany(
    props.address?.street,
    props.address?.district,
    props.address?.city,
    props.address?.state,
    props.address?.zip,
    props.address?.country,
  );


  const addressStreet = (address: any) => {
    let text = '';

    if (validateString(address.street)) text += `${address?.street}, ${textOrEmpty(address.number)} ${textOrEmpty(address.district, '— ')}`;

    return text;
  };


  const getCompleteAddress = (address: any) => {
    let text = '';

    if (validateString(address.place_name)) text += textOrEmpty(address.place_name) + '\n';

    if (validateString(address.street)) text += `${address?.street}, ${textOrEmpty(address.number)}` + '\n' + textOrEmpty(address.district);

    if (validateString(address.complement)) text += '\n' + textOrEmpty(address.complement) + '\n';

    if (validateString(address.city)) text += '\n' + textOrEmpty(address.city) + textOrEmpty(address.state, ', ');

    if (validateString(address.zip)) text += '\n' + Mask.toMask('zip-code', address.zip);
    if (validateString(address.country)) text += '\n' + address.country;

    return text || '-';
  };


  function onOpenDialogMap() {
    if (GetDeviceType() === 'MOBILE') {
      window.open(`google.navigation:q=${props?.address?.latitude},${props?.address?.longitude}&mode=d`, '_system');
    }
    else {
      const url = `http://www.google.com/maps/place/${props?.address?.latitude},${props?.address?.longitude}`;
      openSite(url);
    }
  }


  function copyAddress() {
    setCopied(true);
    navigator.clipboard.writeText(addressStreet(props.address));
    showSnackBarProps(Colors.accept, translate(TranslateConfig.SUCCESS_COPIED_ADDRESS));

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }



  return (

    <CompanyMapView>

      <CompanySubtitleMap>
        {translate(TranslateConfig.LOCATION)}
      </CompanySubtitleMap>



      {validateNumber(props.address?.latitude) && validateNumber(props.address?.longitude) && (
        <MapViewStyle
          onClick={() => {
            onOpenDialogMap();
          }}>

          <MapGoogle
            zoom={15}
            draggable={false}
            center={{
              lat: props?.address?.latitude,
              lng: props?.address?.longitude,
            }}>
            <MapMarker
              renderPin
              lat={props?.address?.latitude}
              lng={props?.address?.longitude}
            />
          </MapGoogle>

        </MapViewStyle>
      )}



      {(!props.noWaze || !props.noUber) && validateNumber(props.address?.latitude) && validateNumber(props.address?.longitude) && (
        <DirectionsView>
          {!props.noWaze && (
            <ButtonSocial
              type={'WAZE'}
              disabled={!addressValid}
              onPress={() => {
                openWaze(
                  validateNumber(props.address?.latitude),
                  validateNumber(props.address?.longitude),
                );
              }}
            />
          )}


          {!props.noUber && (
            <ButtonSocial
              type={'UBER'}
              disabled={!addressValid}
              onPress={() => {
                openUber(
                  validateString(props.companyName),
                  validateNumber(props.address?.latitude),
                  validateNumber(props.address?.longitude),
                  validateString(props.address?.street),
                  validateString(props.address?.number),
                  validateString(props.address?.district),
                );
              }}
            />
          )}
        </DirectionsView>
      )}



      {addressValid
        ? (
          <PlaceAddressView>

            <TextAddressView>
              <SubtitleText>
                {getCompleteAddress(props.address)}
              </SubtitleText>
            </TextAddressView>


            <SocialButton
              style={{
                backgroundColor: copied ? Colors.accept : Colors.cardBackground,
              }}
              onClick={() => {
                copyAddress();
              }}>

              <CopiedItem
                copied={copied}
                icon={
                  <IconCopy
                    size={25}
                    color={Colors.accent}
                  />
                }
              />

            </SocialButton>

          </PlaceAddressView>
        )
        : (
          <PlaceAddressView>

            <SubtitleText>
              {translate(TranslateConfig.LOCATION_NO_ADDRESS)}
            </SubtitleText>

          </PlaceAddressView>
        )}

    </CompanyMapView>

  );
};



export default DetailsAddress;
