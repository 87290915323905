import {
  EAppTranslations,
  EAppTutorialTranslations,
  EButtonsTranslations,
  ECompanyTypesTranslations,
  ECountriesTranslations,
  EDrinksTranslations,
  EEventsTranslations,
  EInterpolatesTranslations,
  EKitchensTranslations,
  ELabelsTranslations,
  ELanguagesTranslations,
  EMenuOrderTranslations,
  EMessagesEmptysTranslations,
  EMessagesErrosTranslations,
  EMessagesHelpsTranslations,
  EMessagesQuestionsTranslations,
  EMessagesSuccessTranslations,
  EMusicsTranslations,
  EPaymentsTranslations,
  EReservesTranslations,
  ESocialsTranslations,
  ETimeTranslations,
  ETitlesTranslations,
  EStatusTranslations,
  ESubtitlesTranslations,
  EValidationsTranslations,
  ECarnivalTranslations,
} from './translates.keys';



export const APP: Record<EAppTranslations, string> = {
  NAME_APP: 'Noitada',
  SHORTNAME_APP: 'Noitada',
  SLOGAN_APP: 'Ecco i migliori motivi per godersi la notte!',
  COMPANY_NAME: 'Noitada',
  COMPANY_APP: 'Per goderti la notte!',
};


export const APP_TUTORIAL: Record<EAppTutorialTranslations, string> = {
  TUTORIAL_TITLE_1: 'Diversão',
  TUTORIAL_TEXT_1: 'Il modo più facile per andare a feste, discoteche, pub o ai tuoi bar preferiti con i tuoi amici!',
  TUTORIAL_TITLE_2: 'Praticità',
  TUTORIAL_TEXT_2: 'Il miglior strumento per scoprire dove sarà la tua prossima Noitada!',
  TUTORIAL_TITLE_3: 'Prezzi',
  TUTORIAL_TEXT_3: 'Sconti e promozioni per tutta la tua Noitada!',
  TUTORIAL_TITLE_4: 'Pronto per goderti la notte?',
};


export const BUTTONS: Record<EButtonsTranslations, string> = {
  ACTION_ACCESS: 'Accedi',
  ACTION_ACCESS_TO_CONTINUE: 'Accedi per continuare',
  ACTION_ACCESS_REGISTER: 'Accedi / Registrati',
  ACTION_REGISTER: 'Registrati',
  ACTION_REGISTER_USER: 'Registrati',
  ACTION_REGISTER_COMPANY: 'Registra azienda',
  ACTION_REGISTER_TO_CONTINUE: 'Registrati per continuare',
  ACTION_CANCEL: 'Annulla',
  ACTION_CONFIRM: 'Conferma',
  ACTION_FORWARD: 'Avanti',
  ACTION_BACK: 'Indietro',
  ACTION_CLOSE: 'Chiudi',
  ACTION_ASK_CHECKIN: 'Richiedi il Check-in',
  ACTION_CANCEL_CHECKIN: 'Annulla Check-in',
  ACTION_CHECKIN_MANUAL: 'Check-in manuale',
  ACTION_CLICK_OPEN: 'Clicca per aprire',
  ACTION_CLICK_CLOSE: 'Clicca per chiudere',
  ACTION_CLICK_CANCEL: ' Clicca per annullare',
  ACTION_CLICK_SELECT: 'Clicca per selezionare',
  ACTION_MORE_DETAILS: 'Clicca per ulteriori dettagli',
  ACTION_LISTS_SEE: 'Visualizza Liste',
  ACTION_SEE_MENUSHEET: 'Visualizza il menu',
  ACTION_PAY: 'Paga',
  ACTION_PAY_APP: 'Pagamento online',
  ACTION_PAY_PRESENTIAL: 'Pagamento in loco',
  ACTION_PAY_ORDERSHEET: 'Paga il conto',
  ACTION_ADD_MORE_ITEMS: 'Aggiungi altri articoli',
  ACTION_BAG_SEE: 'Visualizza la borsa',
  ACTION_BAG_CLEAN: 'Svuota la borsa',
  ACTION_FINISH_ORDER: 'Completa l\'ordine',
  ACTION_CLOSE_ORDERSHEET: 'Chiudi il conto',
  ACTION_SEE_WIFI: 'Visualizza il Wi-Fi',
  ACTION_NO: 'No',
  ACTION_YES: 'Sì',
  ACTION_SEE_HISTORY: 'Visualizza la cronologia completa',
  ACTION_HISTORY: 'Cronologia completa',
  ACTION_SAVE: 'Salva',
  ACTION_OPEN_LIST: 'Clicca per aprire la lista',
  ACTION_OPEN_GALLERY: 'Clicca per visualizzare la galleria',
  ACTION_TRANSFER_ADD: 'Aggiungi account per trasferimento',
  ACTION_COMPANY_ADD: 'Aggiungi azienda',
  ACTION_USER_DISABLE: 'Disabilita profilo',
  ACTION_RECOVER_ACCESS: 'Recupera accesso',
  ACTION_CARD_ADD: 'Aggiungi carta',
  ACTION_ADDRESS_REGISTER: 'Registra indirizzo',
  ACTION_CLICK_EDIT: 'Clicca per modificare',
  ACTION_LOCATION_ENABLED: 'Abilita posizione',
  ACTION_INVITE_LINK: 'Aggiungi tramite link',
  ACTION_ADD_PERSON: 'Aggiungi persona',
  ACTION_ADD_PEOPLE: 'Aggiungi persone',
  ACTION_ADD_MANUAL: 'Aggiungi manualmente',
  ACTION_LIST_PARTICIPATE: 'Partecipa alla lista',
  ACTION_LIST_LEAVE: 'Lascia la lista',
  ACTION_TICKET_SEE: 'Visualizza i biglietti',
  ACTION_COPY_PASSWORD: 'Clicca per copiare la password',
  ACTION_COPY_LINK: 'Copia link',
  ACTION_SEE_DATES: 'Visualizza più date',
  ACTION_TO_RESERVE: 'Prenota',
  ACTION_CANCEL_RESERVE: 'Annulla prenotazione',
  ACTION_SEARCH: 'Cerca',
  ACTION_RESEARCH: 'Ricerca di nuovo',
  ACTION_ADDRESS_SEE_YOURS: 'Clicca per vedere i tuoi indirizzi',
  ACTION_ADDRESS_SEE: 'Visualizza indirizzi',
  ACTION_PAY_FORMS_SEE: 'Visualizza metodi di pagamento',
  ACTION_OPEN_WAZE: 'Apri con Waze',
  ACTION_OPEN_UBER: 'Apri con Uber',
  ACTION_HOUR_SEE: 'Clicca per vedere gli orari',
  ACTION_CLICK_TO_MANAGE: 'Clicca per gestire',
  ACTION_LOCATION_MY: 'Usa la mia posizione attuale',
  ACTION_PROFILE_SEE: 'Visualizza profilo',
  ACTION_CLICK_TO_ADD: 'Clicca per aggiungere',
  ACTION_CLICK_TO_EDIT: 'Clicca per modificare',
  ACTION_TO_SEE_PROFILE: 'Clicca per vedere il tuo profilo',
  ACTION_SKIP: 'Salta',
  ACTION_COME_ON: 'Andiamo!',
  ACTION_PROFILE_MANAGE: 'Fare clic per richiedere questo profilo',
  ACTION_CHECK_ALL: 'Segna tutto',
  ACTION_UNCHECK_ALL: 'Deselezionare tutto',
  ACTION_JOIN_VIA_LIST: 'Iscriviti tramite elenchi',
  ACTION_SEE_AVAILABLES_TICKETS: 'Vedi i biglietti disponibili',
  ACTION_ENTRANCE_IN_EVENT: 'Partecipa all\'evento',
  ACTION_LIST_ENTER: 'Unisciti alla lista',
  ACTION_LIST_EXIT: 'Lascia la lista',
};


export const COMPANY_TYPES: Record<ECompanyTypesTranslations, string> = {
  EVENT_PRODUCER: 'Produttore di eventi',
  EVENT_PRODUCERS: 'Produttori di eventi',
  BAR: 'Bar',
  BARS: 'Bar',
  PUB: 'Pub',
  PUBS: 'Pub',
  NIGHT_CLUB: 'Locale notturno',
  NIGHT_CLUBS: 'Locali notturni',
  RESTAURANT: 'Ristorante',
  RESTAURANTS: 'Ristoranti',
  PIZZERIA: 'Pizzeria',
  PIZZERIAS: 'Pizzerie',
  STEAKHOUSE: 'Churrascaria',
  STEAKHOUSES: 'Churrascarias',
  HAMBURGUER: 'Hamburgueria',
  HAMBURGUERS: 'Hamburguerias',
  COFFEE_SHOP: 'Caffetteria',
  COFFEE_SHOPS: 'Caffetterie',
  CARNIVAL_PRODUCER: 'Produttore di carnevale',
  CARNIVAL_PRODUCERS: 'Produttori di carnevale',
  PARTY_PRODUCER: 'Produttore di festival',
  PARTY_PRODUCERS: 'Produttori di festival',
  GOVERN: 'Governo',
  GOVERNS: 'Governi',
};


export const CARNIVALS: Record<ECarnivalTranslations, string> = {
  CARNIVAL_VOTES: 'Voti di Carnevale',
  CARNIVAL_INTEREST_POINTS: 'Punti di Interesse',
  CARNIVAL_OFFICIAL: 'Carnevale Ufficiale',
  CARNIVAL_MICARETA: 'Micareta',
  CIRCUIT_OFFICIAL: 'Circuito Ufficiale',
  BLOC: 'Blocco',
  CIRCUIT: 'Circuito',
  CIRCUITS: 'Circuiti',
  CABIN: 'PalcoVIP',
  CABINS: 'PalchiVIP',
  POLICE: 'Polizia',
  BUS: 'Autobus',
  TAXI: 'Taxi',
  BATHROOM: 'Bagno',
  FIREMAN: 'Vigile del Fuoco',
  SAVE_LIFE: 'Salvavita',
  MEDICAL_POINT: 'Punto Medico',
  JUVENILE: 'Organizzazione Giovanile',
  OTHER_ATTRACTION: 'Altre Attrazioni',
  AGENCY_FLOW: 'Agenti di Flusso',
  AGENCY_PUBLIC: 'Enti Pubblici',
  CLEAN_HEALTH: 'Salute e Pulizia',
  AREA_RESERVED: 'Aree Riservate',
};


export const COUNTRIES: Record<ECountriesTranslations, string> = {
  BRAZIL: 'Brasile',
  PORTUGAL: 'Portogallo',
  UNITED_STATES: 'Stati Uniti',
  SPAIN: 'Spagna',
  ITALY: 'Italia',
  FANCE: 'Francia',
};


export const DRINKS: Record<EDrinksTranslations, string> = {
  WATER: 'Acqua',
  SODA: 'Bibita',
  VODKA: 'Vodka',
  BEER: 'Birra',
  WINE: 'Vino',
  TEA: 'Tè',
  COFFEE: 'Caffè',
  JUICE: 'Succhi',
  WHISKY: 'Whisky',
  LIQUOR: 'Liquore',
  COCKTAIL: 'Cocktail',
  CHAMPAGNE: 'Champagne',
  GIN: 'Gin',
  RUM: 'Rum',
  ENERGY_DRINK: 'Bevanda energetica',
  SMOOTHIE: 'Smoothie',
  YERBA_MATE: 'Mate',
  SAKE: 'Sake',
  TEQUILA: 'Tequila',
};


export const EVENTS: Record<EEventsTranslations, string> = {
  EVENT_TYPE: 'Tipo di evento',
  EVENT_IDEAL_LGBT: 'Ideale per il pubblico LGBTQIA+',
  EVENT_ABOUT: 'Riguardo all\'evento',
  EVENT_ATTRACTIONS: 'Attrazioni',
  EVENT_START: 'Inizio dell\'evento',
  EVENT_END: 'Fine dell\'evento',
  EVENT_OPENBAR: 'Bevande nell\'OpenBar',
  EVENT_PARENTAL_RATING: 'Classificazione parentale',
  EVENT_PARENTAL_NONE: 'Classificazione libera',
  EVENT_PARENTAL_14: 'Per oltre 14 anni',
  EVENT_PARENTAL_16: 'Per oltre 16 anni',
  EVENT_PARENTAL_18: 'Per oltre 18 anni',
  EVENT_LIST_AVAILABLES: 'Ci sono liste pubbliche disponibili',
  ID_TICKET: 'Biglietto ID',
  TICKET: 'Biglietto',
  TICKETS: 'Biglietti',
  TICKETS_MY: 'I miei biglietti',
  TICKETS_AVAILABLE: 'Ci sono biglietti disponibili',
  EVENT_FREE: 'Evento Gratuito',
  EVENT_DATE: 'Data dell\'evento',
  EVENT_DATE_INITIAL: 'Inizio dell\'evento',
  EVENT_DATE_FINAL: 'Fine dell\'evento',
  TICKET_ENTRANCE_PRICE: 'Prezzi di ingresso e entrata',
  PARTY: 'Festa',
  NIGHT_PARTY: 'Festa notturna',
  NOT_CONFIRMED: 'Non confermato',
};


export const INTERPOLATES: Record<EInterpolatesTranslations, string> = {
  HELLO: 'Ciao',
  PERSON_TO: 'Servire',
  CODE_ABREV: 'Cod.',
  FROM: 'Da',
  TO: 'A',
  PAY_PRESENTIAL: 'Pagamento in loco',
  PAY_BY_APP: 'Paga tramite Noitada',
  PAY_TO: 'Paga a',
  TO_DO: 'Da fare',
  HAVE_WIFI: 'Qui c\'è Wi-Fi',
  INTERPOLATE_ORDERSHEET_CLOSING: 'In attesa che il partner {company_name} chiuda il conto',
  INTERPOLATE_ORDERSHEET_CLOSE: 'Fai clic su "Paga conto" per pagare direttamente con Noitada o per {company_name}',
  INTERPOLATE_ORDERSHEET_FINISH: 'Effettua il pagamento a {company_name} per chiudere il conto',
  AT: 'alle',
  OR: 'o',
  AND: 'e',
  THERE_IS: 'Ci sono',
  LAST_ORDER: 'Ultimo ordine',
  LAST_ORDER_THERE_IS: 'Ultimo ordine da',
  ACCESS_USER_REGISTER: 'Registrandoti su Noitada, accetti i',
  ACCESS_USER_TERMS_USE: 'Termini di Utilizzo',
  ACCESS_USER_TERMS_PRIVACY: 'Norme sulla Privacy',
  INTERPOLATE_LIST_LIMIT: 'Limite di {listAdditionalNumber} ospiti raggiunto!',
  INTERPOLATE_LIST_UNTIL_LIMIT: 'Fino a {listAdditionalNumber} persona(e) nella lista per ospite',
  PRICE_BETWEEN_MIN_MAX: 'Tra {minPrice} e {maxPrice}',
  PRICE_INSTALLMENT_VALUE: 'In fino a {installment} rate con carta di credito',
  RESERVE_PEOPLE_LIMIT: 'Fino a {limitReserve} persona(e) per prenotazione',
  RESERVE_TABLE_AVAILABLE: 'Tavoli disponibili',
  RESERVE_PRICE_PAY: 'Importo di {price} per la prenotazione',
  PAGE_VIEWS: 'Visualizzazioni di questa pagina',
  TERMS_ACCESS_APP: 'Accedendo a Noitada accetti i nostro',
  OPEN: 'Aprire',
  CLOSE: 'Chiuso',
};


export const KITCHENS: Record<EKitchensTranslations, string> = {
  AFRICAN: 'Africana',
  GERMAN: 'Tedesca',
  ARABIC: 'Araba',
  ARGENTINA: 'Argentina',
  DRINKS: 'Bevande',
  BRAZILIAN: 'Brasiliana',
  COFFEE: 'Caffetteria',
  MEATS: 'Carni',
  CHINESE: 'Cinese',
  COLOMBIAN: 'Colombiana',
  KOREAN: 'Coreana',
  SPANISH: 'Spagnola',
  FRENCH: 'Francese',
  SEAFOOD: 'Frutti di mare',
  INDIAN: 'Indiana',
  ITALIAN: 'Italiana',
  JAPANESE: 'Giapponese',
  MEDITERRANEAN: 'Mediterranea',
  MEXICAN: 'Messicana',
  FRIED_PASTRY: 'Pastel',
  FISH: 'Pesce',
  PERUVIAN: 'Peruviana',
  PIZZA: 'Pizza',
  PORTUGUESE: 'Portoghese',
  HEALTHY: 'Salutare',
  JUICES: 'Succhi',
  THAI: 'Tailandese',
  VEGETARIAN: 'Vegetariana',
};


export const LABELS: Record<ELabelsTranslations, string> = {
  ALL: 'Tutto',
  COMPANY: 'Azienda',
  COMPANIES: 'Aziende',
  COMPANIES_MY: 'Le mie aziende',
  PROFILE: 'Profilo',
  PROFILES: 'Profili',
  PROFILES_MY: 'I miei profili',
  PERSONAL_DATA: 'Dati personali',
  PERSONAL_INFORMATIONS: 'Informazioni personali',
  COUNTRY: 'Paese',
  FANTASY_NAME: 'Nome fantasia (Nome pubblico)',
  SOCIAL_REASON: 'Ragione Sociale',
  USER: 'Utente',
  NAME: 'Nome',
  NAME_FULL: 'Nome completo',
  DATE_BORN: 'Data di nascita',
  GENRE: 'Genere',
  DDI_NUMBER_FIRST: 'DDI del telefono principale',
  DDI_NUMBER_SECOND: 'DDI del telefono secondario',
  DDI: 'DDI',
  PERSONS: 'Persona(e)',
  USERNAME: 'Nome utente',
  USERNAME_COMPANY: 'Nome utente dell\'azienda',
  EMAIL: 'E-mail',
  PASSWORD: 'Password',
  PASSWORD_CURRENT: 'Password attuale',
  PASSWORD_NEW: 'Nuova password',
  PASSWORD_REPEAT: 'Ripeti la nuova password',
  PASSWORD_CONFIRM: 'Conferma la password',
  CNPJ: 'CNPJ',
  CPF: 'CPF',
  FORMS: 'Moduli',
  COMMENTS: 'Commenti',
  OPTIONAL: 'Opzionale',
  PUBLIC_ID: 'ID pubblico',
  PUBLIC_PARTNER_ID: 'ID pubblico del partner',
  NUMBER_PRIMARY: 'Numero principale',
  NUMBER_SECONDARY: 'Numero secondario',
  PARTNER_ABOUT: 'Informazioni sul partner',
  PARTNER_SERVICES: 'Servizi disponibili',
  PHONE: 'Telefono',
  PHONES: 'Telefoni',
  PHONE_ONLY_ONE: 'Numero con DDD',
  PHONE_FIRST: 'Telefono principale con DDD',
  PHONE_SECOND: 'Telefono secondario con DDD',
  PARTNER_RULES: 'Regole',
  PARTNER_TYPES_KITCHEN: 'Tipi di cucina',
  PARTNER_OPEN_HOURS: 'Orario di apertura',
  WIFI: 'Wi-Fi',
  KIDS_AREA: 'Area Kids',
  PARKING: 'Parcheggio',
  SMOKING: 'Area Fumatori',
  AIR_CONDITIONER: 'Aria Condizionata',
  ACCESSIBILITY: 'Accessibilità',
  MORE_POPULAR: 'Più popolari',
  NUMBER_OR_NAME: 'Nome o numero',
  INPUT_MODAL_ADD_USER_PLACEHOLDER: 'Ricerca nome...',
  CONFIRM_CPF: 'Conferma CPF del titolare',
  CONFIRM_CNPJ: 'Conferma CNPJ dell\'azienda',
  CONFIRM_INFO: 'Conferma informazione',
  NICKNAME: 'Soprannome',
  DESCRIPTION: 'Descrizione',
  FAVORED: 'Favorito(a)',
  ACCOUNT_USE_MAIN: 'Usa questo account come attuale',
  ADDRESS_USE_MAIN: 'Usa questo indirizzo come attuale',
  INPUT_MODAL_ADD_USER_CARGO: 'Email o nome utente',
  CODE: 'Codice',
  DIGIT: 'Cifra',
  ACCOUNT: 'Conto',
  ID: 'ID',
  ID_PRIVATE: 'ID Privato',
  ID_PUBLIC: 'ID Pubblico',
  COMPANY_STATUS_APP: 'Situazione nella Noitada',
  COMPANY_STATUS_ACTIVE: 'Azienda attiva',
  COMPANY_STATUS_INACTIVE: 'Azienda disabilitata',
  PLACE_NAME: 'Nome del luogo',
  ZIP_CODE: 'CAP',
  STREET: 'Via', // 'Avenue / Via',
  STREETS: 'Strade',
  DISTRICT: 'Quartiere',
  DISTRICTS: 'Quartieri',
  ATTRACTIONS: 'Attrazioni',
  COMPLEMENT: 'Complemento',
  CITY: 'Città',
  CITIES: 'Città',
  STATE: 'Stato',
  SOCIAL: 'Sociale',
  SLOGAN: 'Slogan',
  CELLPHONE: 'Cellulare',
  ABOUT: 'Su',
  ABOUT_APP: 'Su Noitada',
  PROFILE_TYPE: 'Tipo di profilo',
  PARTNER_TYPE: 'Tipo del partner',
  INFORMATIONS: 'Informazioni',
  CARD_HOLDER_NAME: 'Nome del Titolare',
  CARD_NICKNAME: 'Soprannome della Carta',
  MAN: 'Maschile',
  WOMAN: 'Femminile',
  SOCIAL_NAME: 'Nome sociale',
  ABOUT_ME: 'Su di me',
  LEVEL: 'Livello',
  BIRTHDAY: 'Compleanno',
  PICTURE: 'Foto',
  USER_PRE_REGISTER: 'Utente pre-registrato',
  DATAS: 'Dati',
  SHOPS: 'Acquisti',
  OPTIONS: 'Opzioni',
  ME_AND_GUESTS: 'Io e ospiti',
  ONLY_YOU: 'Solo tu',
  ONLY_FOR_ME: 'Solo per me',
  PEOPLE: 'Persone',
  RESERVE_DATE: 'Data della prenotazione',
  DATE_SELECTED: 'Data selezionata',
  ENVIRONMENT: 'Ambiente',
  PEOPLE_NUMBER: 'Numero di persone',
  MORE_INFORMATIONS: 'Ulteriori informazioni',
  OBSERVATIONS: 'Osservazioni',
  PRIORITY: 'Priorità',
  INITIAL_DATE: 'Data di inizio',
  INITIAL_HOUR: 'Ora di inizio',
  FINAL_DATE: 'Data di fine',
  FINAL_HOUR: 'Ora di fine',
  INITIAL_VALUE: 'Valore iniziale',
  FINAL_VALUE: 'Valore finale',
  PARAMETERS: 'Parametri',
  NAME_OR_USERNAME: 'Nome o Nome utente',
  MODALITIES: 'Modalità',
  VERSION: 'Versione',
  MY_ACCOUNT: 'Il mio account',
  ACCOUNT_SEE: 'Vedi account',
  ACCOUNT_ACTIONS: 'Azioni dell\'account',
  PAYMENTS_FORMS: 'Forme di Pagamento',
  LOCATION: 'Posizione',
  LOCATION_NO_ADDRESS: 'Nessuna informazione sull\'indirizzo',
  SALES: 'Saldi',
  TICKETS_SALES_UNTIL: 'Vendite fino a',
  PARTICIPATION: 'Partecipazione',
  WAIT_YOU: 'Ti aspettiamo',
  ITEMS: 'Oggetti',
  HELP: 'Aiuto',
  TYPE: 'Tipo',
  OTHER: 'Altro',
  ADD: 'Aggiungi',
  ACTUAL: 'Attuale',
  COUNTRIES: 'Paesi',
  NO: 'No',
  YES: 'Sì',
  LIST_NAME: 'Nome della lista',
  GUIDES_INFORMATIONS: 'Guide e informazioni',
  PHONES_USEFUL: 'Telefoni utili',
  CASADINHA: 'Casadinha',
  OPEN_BAR: 'Open Bar',
  LIST: 'Lista',
  VIP: 'VIP',
  LIST_ID: 'ID elenco',
  LIST_FROM: 'Elenco di',
};


export const LANGUAGES: Record<ELanguagesTranslations, string> = {
  PT_BR: 'Portoghese (Brasile)',
  EN_US: 'Inglese (USA)',
  ES_AR: 'Spagnolo (Spagna)',
  IT_IT: 'Italiano (Italia)',
  FR_FR: 'Francese (Francia)',
};


export const MENU_ORDER: Record<EMenuOrderTranslations, string> = {
  COMBO: 'Combo',
  ITEM_DOUBLE: 'Articolo doppio',
  AVAILABLES: 'Disponibile',
  TABLE: 'Tavolo',
  NUMBER: 'Numero',
  NUMBER_TABLE_ENTRANCE: 'Numero del tavolo / ingresso',
  TABLE_ENTRANCE: 'Tavolo / ingresso',
  NO_MIN_ORDERSHEET: 'Nessun ordine minimo',
  MIN_ORDERSHEET: 'Ordine minimo',
  WAITER_CALL: 'Chiamare il cameriere',
  WAITER_CALLING: 'Chiamata del cameriere',
  WAITER_GOING: 'Cameriere in arrivo',
  ORDERSHEET_CLOSING_ORDERSHEET: 'Chiusura scheda ordini',
  ORDERSHEET_CLOSED: 'Scheda ordini chiusa',
  ORDERSHEET_OPENED: 'Scheda ordini aperta',
  TOTAL_CONSUMPTION: 'Consumo totale',
  ALCOHOLIC: 'Alcolico',
  SPICY: 'Piccante',
  VEGETARIAN: 'Vegetariano',
  VEGAN: 'Vegano',
  ORGANIC: 'Biologico',
  NO_LACTOSE: 'Senza lattosio',
  NO_GLUTEN: 'Senza glutine',
  REQUESTS: 'Richieste',
  REQUESTS_MINIMAL: 'Richieste minime',
  NO_VALUE_MINIMAL: 'Nessun valore minimo',
  REQUESTS_UNTIL: 'Richieste fino a',
  DELIVERY: 'Consegna',
  REMOVAL_ITEM: 'Rimozione',
  PRESENTIAL: 'Presenziale',
  ORDER_PLACED: 'Ordine effettuato',
  ORDER_PREPARING: 'In preparazione',
  ORDER_TO_DELIVER: 'Consegnare',
  ORDER_REFUSED: 'Ordine rifiutato',
};


export const MESSAGES_EMPTYS: Record<EMessagesEmptysTranslations, string> = {
  EMPTY_COMPANY_GALLERY: 'Spiacente, nessun media per questo partner!',
  EMPTY_COMPANY_NOT_FOUND: 'Spiacente, profilo non trovato!',
  EMPTY_COMPANY_EVENTS: 'Non ci sono eventi qui, ancora!',
  EMPTY_EVENTS_NOT_FOUND: 'Spiacente, evento non disponibile!',
  EMPTY_HIGHLIGHTS_ITEMS: 'Spiacente, nessun elemento in evidenza trovato oggi!',
  EMPTY_HIGHLIGHTS_EVENTS: 'Non ci sono eventi qui, ancora!',
  EMPTY_HIGHLIGHTS_PARTNERS: 'Nessun partner è attualmente nella Noitada qui!',
  EMPTY_CATEGORIES: 'Nessuna categoria',
  EMPTY_MENUSHEET_ITEMS: 'Spiacente, nessun elemento nel menu!',
  EMPTY_MENUSHEET_CATEGORIES: 'Spiacente, nessun elemento in questa categoria!',
  EMPTY_NOTIFICATIONS: 'Non ci sono notifiche qui, ancora!',
  EMPTY_ORDERSHEET_HISTORY: 'Nessun elemento nello storico, ancora!',
  EMPTY_BANKS: 'Nessuna banca qui, spiacente!',
  EMPTY_COMPANIES_REGISTERED: 'Non ci sono aziende registrate!',
  EMPTY_HOME: 'Nessun dato da mostrare!',
  EMPTY_FAVORITES: 'Non ci sono preferiti qui!',
  EMPTY_PAYMENT: 'Nessun metodo di pagamento registrato!',
  EMPTY_ADDRESS: 'Nessun indirizzo registrato!',
  EMPTY_USER_DATA: 'Utente senza dati',
  EMPTY_USER_NO_RECOG: 'Utente non riconosciuto',
  EMPTY_LISTS: 'Nessuna lista registrata',
  EMPTY_LISTS_PARTICIPATE: 'Nessuna lista a cui partecipare, ancora!',
  EMPTY_LISTS_FINISHED: 'Nessuna lista a cui hai partecipato, ancora!',
  EMPTY_TICKET: 'Nessun biglietto ancora!',
  EMPTY_TICKET_IN_EVENT: 'Nessun biglietto per questo evento',
  EMPTY_LIST_IN_EVENT: 'Nessun elenco pubblico disponibile',
  EMPTY_EVENT_EXPLORE: 'Esplora altri eventi simili',
  EMPTY_CARD_REGISTERED: 'Nessuna carta registrata',
  EMPTY_CARD_NO_ADDED: 'Nessuna carta nel tuo account',
  EMPTY_RESERVE_REGISTERED: 'Nessuna prenotazione disponibile o registrata!',
  EMPTY_RESERVE_USER: 'Nessuna prenotazione ancora per goderti la serata!',
  EMPTY_LINK_SEE: 'Nessun link da visualizzare',
  EMPTY_SEARCH: 'Spiacente, nessun risultato nella ricerca!',
  EMPTY_ORDERS: 'Nessun ordine',
  EMPTY_EVENT_FINISHED: 'L\'evento è già terminato!',
  EMPTY_PLACES: 'Nessun luogo registrato, ancora!',
  EMPTY_CARNIVAL_ATTRACTIONS: 'Spiacente, nessuna attrazione oggi!',
  EMPTY_CARNIVAL_NONE: 'Siamo spiacenti, non c\'è ancora carnevale o micaretas!',
  EMPTY_CARNIVAL_DISTRICTS: 'Spiacente, nessuna festa nei quartieri oggi!',
  EMPTY_CARNIVAL_CABINS: 'Spiacente, i palchi sono chiusi oggi!',
  EMPTY_CARNIVAL_LOCATIONS: 'Spiacente, nessuna posizione qui!',
  EMPTY_CARNIVAL_STREETS: 'Spiacente, nessuna festa in strada oggi!',
  EMPTY_CARNIVAL_INFOS: 'Spiacente, nessuna guida qui!',
  EMPTY_CARNIVAL_PHONES: 'Spiacente, nessun telefono qui!',
  EMPTY_CARNIVAL_VOTES: 'Siamo spiacenti, nessun voto ancora!',
  EMPTY_LANGUAGES: 'Nessuna lingua, ancora!',
  EMPTY_LIST_AVAILABLE: 'Nessun elenco disponibile',
  EMPTY_TICKET_AVAILABLE: 'Non ci sono biglietti disponibili',
  EMPTY_WAIT_LIST_AVAILABLE: 'Nessuna lista d\'attesa qui!',
  EMPTY_WAIT_LIST_ENTER_USER: 'Fare clic sul pulsante per iscriversi alla lista d\'attesa',
  EMPTY_WAIT_LIST_USER: 'Registrati o accedi per iscriverti alla lista',
  EMPTY_WAIT_LIST_EXIT_UNAVAILABLE: 'Lascia l\'elenco dei non disponibili',
};


export const MESSAGES_ERRORS: Record<EMessagesErrosTranslations, string> = {
  ERROR: 'Errore',
  OOPS: 'Oops',
  ERROR_PAY: 'Errore durante il pagamento',
  ERROR_PAY_APP: 'Abbiamo riscontrato un problema, effettua il pagamento presso l\'esercizio',
  ERROR_CHECKIN_ASK: 'Impossibile richiedere il Check-in',
  ERROR_CHECKIN_CANCEL: 'Impossibile annullare il Check-in',
  ERROR_HAD: 'Si è verificato un errore',
  ERROR_SEARCH: 'Errore durante la ricerca dei dati',
  TRY_AGAIN: 'Riprova',
  ACCESS_DENIED: 'Nessuna autorizzazione di accesso per questo utente.',
  PASSWORD_WRONG: 'Credenziali utente e/o password non valide.',
  ERROR_PARAMS_INVALID: 'Parametri non validi',
  ERROR_COMPANY_CREATE: 'Errore durante la creazione dell\'azienda',
  ERROR_USER_CREATE: 'Errore durante la creazione del profilo',
  ERROR_DATE: 'Errore nella data',
  ERROR_NO_DATA_FOUND: 'Dati non trovati, verifica le informazioni e riprova.',
  ERROR_EMPTY_FIELDS: 'Compila tutti i campi correttamente prima di continuare',
  ERROR_ACCOUNT_TRANSFER: 'Errore durante la creazione del conto di trasferimento',
  ERROR_ACCOUNT_SEARCH: 'Errore durante la ricerca dei conti di trasferimento',
  ERROR_ACCOUNT_MAIN_CHANGE: 'Si è verificato un errore durante il cambio del conto principale',
  ERROR_ACCOUNT_DELETE: 'Si è verificato un errore durante l\'eliminazione del conto',
  ERROR_SAVE: 'Errore durante il salvataggio dei dati!',
  ERROR_CREATE: 'Errore durante la creazione',
  ERROR_UPDATE: 'Errore durante l\'aggiornamento',
  ERROR_USER_DISABLE: 'Errore durante la disabilitazione dell\'utente.',
  ERROR_COMPANY_DISABLE: 'Errore durante la disabilitazione dell\'azienda.',
  ERROR_MAKE_REGISTER: 'Errore durante la registrazione',
  ERROR_PAYMENT_MAIN_CHOOSED: 'Si è verificato un errore durante la scelta del pagamento principale',
  ERROR_PAYMENT_DELETE: 'Si è verificato un errore durante l\'eliminazione del pagamento',
  ERROR_ADDRESS_DELETE: 'Si è verificato un errore durante l\'eliminazione dell\'indirizzo',
  ERROR_LIST_USER_ADDED: 'Errore durante l\'aggiunta degli utenti',
  ERROR_LIST_ENTER: 'Errore durante il tentativo di entrare nella lista',
  ERROR_LIST_GET_DATA: 'Errore durante l\'ottenimento delle liste',
  ERROR_GET_DATA: 'Errore durante l\'ottenimento dei dati',
  ERROR_TICKET_UNAVAILABLES: 'Biglietti non disponibili',
  ERROR_LISTS_UNAVAILABLES: 'Liste non disponibili',
  ERROR_USER_DOESNOT_EXIST: 'L\'utente non esiste!',
  ERROR_USER_IN_LIST: 'L\'utente è già nella lista',
  ERROR_FAVORITE_ADD: 'Errore durante l\'aggiunta ai preferiti',
  ERROR_FAVORITE_REMOVE: 'Errore durante la rimozione dai preferiti',
  ERROR_WAIT_LISTS_ENTER: 'Errore durante l\'iscrizione alla lista d\'attesa',
  ERROR_WAIT_LISTS_EXIT: 'Errore nell\'uscita dalla lista d\'attesa',
};


export const MESSAGES_HELP: Record<EMessagesHelpsTranslations, string> = {
  HELP_INPUT_COMPANY: 'Inserisci i dati dell\'azienda',
  HELP_INPUT_USER: 'Inserisci le informazioni seguenti',
  HELP_INPUT_REQUIRED: 'Campi obbligatori',
  HELP_QR: 'Scansiona il codice QR del partner per effettuare il Check-in, evitare le code e velocizzare l\'accesso a luoghi ed eventi!',
  HELP_CHECKIN: 'Per effettuare il Check-in, inserisci l\'ID pubblico del Partner, ad esempio il numero del tavolo, e attendi la conferma del tuo Check-in.',
  HELP_CHECKIN_WAIT: 'Attendi la conferma del Check-in. Se lo desideri, puoi annullare la richiesta di Check-in in qualsiasi momento.',
  HELP_EVENT_RATING: 'La classificazione e le informazioni sull\'evento sono responsabilità degli organizzatori e dei produttori.',
  HELP_EVENT_ALCOHOL: 'La vendita di bevande alcoliche è vietata ai minori di 18 anni.',
  HELP_PAY_UNAVAILABLE: 'Pagamento online non disponibile. Nessuna carta registrata',
  HELP_SUCCESS_ORDER_DONE: 'Puoi seguire lo stato degli articoli direttamente nella cronologia della comanda.',
  HELP_CHEKIN_HOW_TO: 'All\'ingresso del locale o dell\'evento, mostra questo codice QR per convalidare i biglietti, le liste, le prenotazioni e gli ordini di ingresso.',
  HELP_ORDERSHEET_CLOSING: 'In chiusura',
  HELP_ORDERSHEET_THANKS: 'Grazie per aver utilizzato Noitada',
  HELP_ORDERSHEET_PARTNER_CLOSE: 'La tua comanda è stata chiusa dal partner',
  HELP_ORDERSHEET_RECUSED: 'Ordine della comanda rifiutato',
  HELP_ORDERSHEET_NOT_FOUND: 'Check-in non accettato o non trovato',
  HELP_SOME: 'Qualche dubbio?',
  HELP_TALK_TO_US: 'Parla con Noitada',
  HELP_ACCOUNT_TRANSFER: 'Per effettuare il trasferimento, il conto bancario da inserire deve appartenere alla persona registrata o all\'azienda.',
  HELP_ACCOUNT_BANK_RECEIVE: 'La banca ricevente può scegliere in quale modalità accreditare il trasferimento se i conti corrente e di risparmio hanno lo stesso numero.',
  HELP_ACCOUNT_LIST_TRANSFER: 'Registra un conto bancario e trasferisci i tuoi soldi su di esso.',
  HELP_ACCOUNT_LIST_TRANSFER_TEXT: 'Per trasferire denaro sul tuo conto bancario, è necessario registrare un conto bancario a tuo nome o dell\'azienda.',
  HELP_PROFILE_PICTURE: 'Una foto aiuta le persone a riconoscerti e ti permette di sapere quale account è connesso.',
  HELP_LOGOTIPO_COMPANY_TEXT: 'Un logotipo aiuta a personalizzare il profilo e permette di trovarlo più rapidamente.',
  HELP_LOGOTIPO_COMPANY_DIMENSIONS: 'Consigliamo un\'immagine quadrata (1x1), di dimensioni approssimativamente 400x400.',
  HELP_COMPANY_COMPLETE_PROFILE: 'Completa il profilo della tua azienda per utilizzare i servizi di Noitada',
  HELP_USER_DISABLE: 'Se desideri disattivare il tuo account, ci prenderemo cura di questo per te. Vorremmo informare i nostri utenti che, anche se l\'account viene disattivato, per motivi di sicurezza, non sarà possibile creare un altro account sulla nostra piattaforma, poiché può esserci un solo account per CPF.',
  HELP_USER_DISABLE_BACK: 'Puoi tornare in qualsiasi momento accedendo al tuo account con la password già registrata e, se dimentichi, puoi reimpostarla.',
  HELP_COMPANY_DISABLE_BACK: 'Per riattivare questa azienda, devi contattare il supporto e richiedere l\'attivazione.',
  HELP_COMPANY_DISABLE_PLAN: 'Se questa azienda ha un piano, verrà anche cancellato!',
  HELP_WELCOME_TITLE: 'Benvenuto nel tuo Account',
  HELP_WELCOME_DESCRIPTION: 'Gestisci le tue informazioni, la privacy e la sicurezza in modo che Noitada soddisfi le tue esigenze.',
  HELP_PASSWORD_NEW: 'Inserisci e conferma la tua nuova password, è con essa che potrai accedere a Noitada',
  HELP_PASSWORD_OLD: 'Inserisci la tua password attuale per poter confermare che sei tu, poi inserisci e conferma la tua nuova password',
  HELP_PASSWORD_REMENBER: 'Se non ricordi la tua password attuale, dovrai',
  HELP_PASSWORD_REDEFINE: 'reimpostarla',
  HELP_PASSWORD_FORGOT: 'Se hai dimenticato la password, inserisci l\'indirizzo email che hai utilizzato per registrarti su Noitada.',
  HELP_INVITE_PEOPLE: 'Puoi invitare più persone ora o in seguito.',
  HELP_INVITE_LINK: 'Condividi questo link con i tuoi invitati perché possano aggiungere il loro nome a questa lista.',
  HELP_LIST_HOW_INVITE: 'Inserisci il nome utente del tuo invitato o, nel caso in cui la persona non sia su Noitada, inserisci il suo CPF.',
  HELP_LIST_HOW_FINISH: 'Aggiungendo una persona, la sua partecipazione sarà confermata automaticamente al termine!',
  HELP_WIFI_NO_REGISTER: 'Nessun Wi-Fi registrato, devi chiedere per sapere se il Wi-Fi è disponibile.',
  HELP_RESERVE_MINUTES: 'Dopo 30 minuti dall\'orario prenotato, se non c\'è nessuno per convalidare la prenotazione, questa verrà chiusa e il tavolo sarà liberato.',
  HELP_RESERVE_AGE: 'Età minima pagante 4 anni.',
  HELP_RESERVE_TABLE: 'La scelta di unire i tavoli è a discrezione del Partner.',
  HELP_RESERVE_TAXES: 'Possibili tasse come ad esempio parcheggio o area giochi non sono incluse nel prezzo della prenotazione.',
  HELP_RESERVE_CANCEL: 'Se nessuno può partecipare, annulla la prenotazione in anticipo. L\'annullamento può essere effettuato fino a 3 ore prima dell\'orario prenotato, dopo questo orario l\'annullamento non sarà più possibile.',
  HELP_MINIMAL_AGE: 'Con un minimo di 4 anni',
  HELP_RESERVE_OBS_EXAMPLE: 'Esempio: Compleanno, Celebrare',
  HELP_RESERVE_QUANTITY_PARTNER: 'La quantità di persone nella prenotazione sarà la quantità accettata, spetta al partner identificare e contare la quantità di persone per questa prenotazione e se accetterà più persone di quanto previsto',
  HELP_SEARCH_BY_NAME: 'Cerca per nome o nome utente',
  HELP_SEARCH_PARAMETERS: 'Cerca per parametri',
  HELP_LOGIN_TO_SEE: 'Accedi per vedere le tue informazioni',
  HELP_INSERT_USER: 'Inserisci l\'utente',
  HELP_USER_HAVENT_DATA: 'Non ho alcuna informazione',
  HELP_COMPANY_QUANTITY_ACCEPT: 'L\'accettazione della quantità di persone è a discrezione del partner. Lui è responsabile di identificare e contare la quantità di persone partecipanti e se accetterà più persone di quanto descritto.',
  HELP_PROFILE_VERIFY_UNMANAGE: 'Profilo non gestito. Fai attenzione e controlla sempre le informazioni e i prezzi sul sito perché potrebbero non essere aggiornati.',
  HELP_PROFILE_UNMANAGE: 'Il profilo per questa località non è stato ancora richiesto. I proprietari che richiedono un profilo possono aggiornare le proprie informazioni, effettuare vendite, creare prenotazioni, aggiungere foto e altro ancora.',
  HELP_PROFILE_MANAGE: 'Una persona collegata a questa sede gestisce questo profilo.',
  HELP_ORDERSHEET_DETAILS_ENABLED: 'Puoi utilizzare la consumazione Noitada per acquistare e pagare questo evento.',
  HELP_ORDERSHEET_DETAILS_DISABLED: 'Il consumo viene disattivato dal produttore, al tuo arrivo sul posto dovrai chiedere come acquistare e pagare il tuo ordine.',
};


export const MESSAGES_QUESTIONS: Record<EMessagesQuestionsTranslations, string> = {
  ANY_QUESTION: 'Qualsiasi domanda',
  ANY_QUESTION_CONTACT: 'contattaci',
  PAY_ATTENTION: 'Fai attenzione',
  QUESTION_DELETE_CARD: 'Eliminare la carta?',
  QUESTION_DELETE_CARD_TEXT: 'Vuoi eliminare questa carta?',
  QUESTION_ORDERSHEET_CLOSE: 'Chiudere il foglio d\'ordine?',
  QUESTION_ADD_CREDIT_CARD: 'Nessuna carta di credito nel tuo account, desideri aggiungerne una?',
  QUESTION_ACCOUNT_MAIN_CHANGE: 'Cambiare conto bancario principale?',
  QUESTION_ACCOUNT_MAIN_CHANGE_TEXT: 'Vuoi impostare questo conto come principale?',
  QUESTION_ACCOUNT_DELETE: 'Eliminare il conto bancario?',
  QUESTION_ACCOUNT_DELETE_TEXT: 'Vuoi eliminare questo conto bancario?',
  QUESTION_USER_DISABLE: 'Sei sicuro di voler disattivare il tuo account?',
  QUESTION_COMPANY_DISABLE: 'Sei sicuro di voler disattivare questa azienda?',
  QUESTION_ADDRESS_REGISTER_TEXT: 'Per aggiungere un metodo di pagamento è necessario registrare un indirizzo principale. Vuoi farlo ora?',
  QUESTION_ADDRESS_DELETE: 'Eliminare l\'indirizzo?',
  QUESTION_ADDRESS_DELETE_TEXT: 'Vuoi eliminare questo elemento?',
  QUESTION_BAG_ITEM_REMOVE: 'Vuoi rimuovere {itemSaleName} dal tuo carrello?',
  QUESTION_BAG_CLEAR: 'Svuotare il carrello?',
  QUESTION_EXIT: 'Vuoi uscire',
  QUESTION_FAVORITE_REMOVE: 'Rimuovere dai preferiti?',
  QUESTION_WAIT_LISTS_USER_EXIT: 'Sei sicuro di voler uscire dalla lista d\'attesa?',
  QUESTION_CARD_NO_ADDED: 'Vuoi aggiungere metodi di pagamento adesso?',
};


export const MESSAGES_SUCCESS: Record<EMessagesSuccessTranslations, string> = {
  SUCCESS: 'Successo',
  SUCCESS_CHECKIN_ASK: 'Check-in confermato. Goditi!',
  SUCCESS_CHECKIN_CANCEL: 'Richiesta di check-in cancellata!',
  SUCCESS_ADD_ITEM_BAG: 'Elemento aggiunto con successo!',
  SUCCESS_ORDER_DONE: 'Ordine effettuato!',
  SUCCESS_PAYED: 'Pagato!',
  SUCEESS_COMPANY_CREATE: 'Azienda creata con successo!',
  SUCEESS_COMPANY_CREATE_TEXT: 'Ora devi attendere la nostra valutazione dei dati.',
  SUCEESS_USER_CREATE: 'Profilo creato con successo!',
  SUCEESS_USER_CREATE_TEXT: 'Abbiamo inviato un\'e-mail di conferma a {user_email}. Apri l\'e-mail, conferma la creazione del profilo e potrai accedere utilizzando le credenziali registrate. Il tuo numero cliente è anche nell\'e-mail.',
  SUCCESS_ACCOUNT_TRANSFER: 'Conto di trasferimento creato con successo',
  SUCCESS_ACCOUNT_MAIN_CHANGE: 'Nuovo conto principale cambiato',
  SUCCESS_ACCOUNT_DELETE: 'Conto eliminato con successo',
  SUCCESS_CREATE: 'Creato con successo',
  SUCCESS_UPDATE: 'Aggiornamento effettuato con successo',
  SUCCESS_USER_DISABLE: 'Utente disabilitato con successo',
  SUCCESS_COMPANY_DISABLE: 'Azienda disabilitata con successo',
  SUCCESS_PASSWORD_RECOVER: 'Richiesta di recupero password effettuata con successo!',
  SUCCESS_PASSWORD_RECOVER_TEXT: 'Abbiamo inviato un\'e-mail di conferma a {user_email}. Apri l\'e-mail, fai clic sul link e potrai cambiare la tua password.',
  SUCCESS_CARD_REGISTER: 'Carta registrata con successo',
  SUCCESS_PAYMENT_MAIN_CHOOSED: 'Pagamento principale scelto',
  SUCCESS_PAYMENT_DELETE: 'Pagamento eliminato con successo',
  SUCCESS_ADDRESS_DELETE: 'Indirizzo eliminato con successo',
  SUCCESS_EMAIL_CHANGE: 'Email di cambio inviata con successo',
  SUCCESS_LIST_USER_ADDED: 'Utenti aggiunti con successo',
  SUCCESS_PASSWORD_COPY: 'Password copiata',
  SUCCESS_USER_ADDED: 'Utente aggiunto con successo',
  SUCCESS_FAVORITE_ADD: 'Preferito aggiunto con successo',
  SUCCESS_FAVORITE_REMOVE: 'Preferito rimosso con successo',
  SUCCESS_COPIED_ADDRESS: 'Indirizzo copiato',
  SUCCESS_COPIED_LINK: 'Collegamento copiato',
  SUCCESS_WAIT_LISTS_EXIT: 'Uscita dalla lista d\'attesa con successo',
};


export const MUSICS: Record<EMusicsTranslations, string> = {
  EVENT_MUSICAL_GENRES: 'Generi Musicali',
  MUSIC: 'Musica',
  ELECTRONIC: 'Elettronica',
  FUNK: 'Funk',
  COUNTRY_MUSIC: 'Country Music',
  POP: 'Pop',
  RAP: 'Rap',
  ROCK: 'Rock',
  MPB: 'MPB',
  AXE: 'Axé',
  SAMBA: 'Samba',
  REGGAE: 'Reggae',
  FORRO: 'Forró',
  GOSPEL: 'Gospel',
  HIP_HOP: 'Hip-Hop',
  JAZZ: 'Jazz',
  CLASSIC_MUSIC: 'Musica Classica',
  PAGODE: 'Pagode',
  BLUES: 'Blues',
};


export const PAYMENTS: Record<EPaymentsTranslations, string> = {
  BANK: 'Banca',
  AGENCY: 'Agenzia',
  CARD_SELECTED: 'Carta selezionata',
  DEBIT_CARD: 'Carta di debito',
  CREDIT_CARD: 'Carta di credito',
  CREDIT: 'Credito',
  DEBIT: 'Debito',
  TICKETS_CARD: 'Biglietti',
  CASH: 'Contanti',
  BANK_CHECK: 'Assegno bancario',
  PIX: 'Pix',
  ELO: 'Elo',
  MASTERCARD: 'Mastercard',
  VISA: 'Visa',
  AMERICAN: 'American',
  DINERS: 'Diners',
  HIPERCARD: 'Hipercard',
  ALELO: 'Alelo',
  BEN: 'Ben',
  SODEXO: 'Sodexo',
  CARD_TICKET: 'Biglietto',
  VR: 'Vr',
  PAY_CHANGE: 'Cambio',
  DISCOUNT: 'Sconto',
  TIP: 'Mancia',
  TOTAL_PAY: 'Totale pagato',
  TOTAL: 'Totale',
  TOTAL_BAG: 'Borsa totale',
  SUBTOTAL: 'Subtotale',
  TAXES: 'Tasse e oneri',
  TAX_DELIVERY: 'Tariffa di consegna',
  PARTNER_PAYMENTS_ON: 'Pagamenti accettati in loco',
  WAITING_PAYMENT: 'In attesa di pagamento',
  ALL_BANKS: 'Tutte le banche',
  ACCOUNT_CURRENT: 'Conto corrente',
  ACCOUNT_SAVING: 'Conto di risparmio',
  ACCOUNT_NICKNAME: 'Soprannome del conto',
  ACCOUNT_BELONGING: 'Conto appartenente a',
  ACCOUNT_CURRENT_SAVING_SAME_NUMBER: 'Conti corrente e di risparmio con lo stesso numero',
  CARD_ACCEPTED: 'Carte accettate presso Noitada',
  PAYMENT_FORM: 'Forma di pagamento',
  CARD_NUMBER: 'Numero della carta',
  DATE_VALID_UNTIL: 'Valido fino al',
  CARD: 'Carta',
  CARDS: 'Carte',
  CARD_CVV: 'CVV',
  CARD_VALIDITY: 'Validità',
  CARD_CURRENCY: 'Carta attuale',
  CARD_DEFINE_CURRENCY: 'Imposta carta attuale',
  CARD_DELETE: 'Elimina carta',
  CARD_CHOOSE_MAIN: 'Usa questa carta come attuale',
  CARD_CHOOSE_DEBIT_TOO: 'Se il pagamento con carta di credito fallisce, tenta con la carta di debito (se disponibile)',
  CARD_CHOOSE_CREDIT_TOO: 'Se il pagamento con carta di debito fallisce, tenta con la carta di credito (se disponibile)',
  AT_SIGHT: 'A vista',
  INSTALLMENT_TIME: 'Rate',
  FEE_PROCESSING: 'Tassa di elaborazione',
  PER_MONTH: 'Al mese',
};


export const RESERVES: Record<EReservesTranslations, string> = {
  RESERVE_TO: 'Prenota per',
  RESERVE_PRICE: 'Importo della prenotazione',
  RESERVE_FREE: 'Prenotazione gratuita',
  RESERVED_TO: 'Prenotato per',
  RESERVED_AT: 'Prenotato alle',
  RESERVE_MADE_TO: 'Prenotazione effettuata da',
  RESERVE_HOUR: 'Orario della prenotazione',
  RESERVE_PEOPLE: 'Persone nella prenotazione',
};


export const SOCIALS: Record<ESocialsTranslations, string> = {
  WHATSAPP: 'WhatsApp',
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
  TWITTER: 'X Twitter',
  YOUTUBE: 'Youtube',
  LINKEDIN: 'LinkedIn',
  THREADS: 'Threads',
  TIKTOK: 'TikTok',
  CONTACT_US: 'Contattaci',
  SEND_MESSAGE: 'Invia un messaggio',
};


export const TIME: Record<ETimeTranslations, string> = {
  DATE: 'Data',
  HOUR: 'Ora',
  DATE_HOUR: 'Data e Ora',
  DATES_AVAILABLE: 'Date disponibili',
  OPENED_UNTIL: 'Aperto fino alle',
  OPENED: 'Aperto',
  OPENEDS: 'Aperti',
  CLOSE_IN: 'Chiude in',
  CLOSED: 'Chiuso',
  CLOSEDS: 'Chiusi',
  TODAY: 'Oggi',
  SUNDAY: 'Domenica',
  MONDAY: 'Lunedì',
  TUESDAY: 'Martedì',
  WEDNESDAY: 'Mercoledì',
  THURSDAY: 'Giovedì',
  FRIDAY: 'Venerdì',
  SATURDAY: 'Sabato',
  NEXT: 'Prossimi',
  PAST: 'Passati',
  UPDATED_AT: 'Aggiornato',
};


export const TITLES: Record<ETitlesTranslations, string> = {
  HOME: 'Home',
  FAVORITES: 'Preferiti',
  EXIT: 'Esci',
  FORGOT_PASSWORD_QUESTION: 'Hai dimenticato la password?',
  RECOVER_PASSWORD: 'Recupera password',
  REGISTER: 'Registra',
  ACCESS: 'Accedi',
  CHECKIN: 'Check-in',
  CHECKOUT: 'Check-out',
  CHECKIN_WAIT: 'In attesa di Check-in',
  QR_READER: 'Non vuoi perdere tempo?',
  QR_MY_CODE: 'Il mio codice QR',
  GALLERY: 'Galleria',
  COMPANY_DETAILS: 'Dettagli del partner',
  ALL_EVENTS: 'Tutti gli eventi',
  LISTS: 'Liste',
  LISTS_EVENT: 'Liste dell\'evento',
  LISTS_ALL: 'Tutte le liste',
  LISTS_AVAILABLES: 'Liste disponibili',
  LISTS_SPECIALS: 'Liste speciali',
  MENUSHEET: 'Menu',
  HIGHLIGHTS: 'Evidenzia',
  PARTNER: 'Partner',
  PARTNERS: 'Partner',
  PARTNERS_HIGHLIGHTS: 'Profili in evidenza oggi',
  EVENTS: 'Eventi',
  EVENTS_HIGHTLIGHTS: 'Eventi in evidenza oggi',
  EVENTS_MORE: 'Eventi per te',
  EVENTS_STREET: 'Eventi di strada',
  MAP: 'Mappa',
  MAPS: 'Mappe',
  NOTIFICATIONS: 'Notifiche',
  NOTIFICATIONS_CONFIG: 'Configura notifiche',
  ORDERSHEET: 'Comanda',
  ORDERSHEET_PAY: 'Paga Comanda',
  PAYMENT_OPTIONS: 'Opzioni di pagamento',
  ORDERBAG: 'Borsa',
  HISTORIC: 'Cronologia',
  COMPANY_NEW: 'Nuova azienda',
  COMPANY_REGISTER: 'Registra azienda',
  BANKS: 'Banche',
  MODAL_ADD_USER: 'Aggiungi utente all\'azienda',
  ACCOUNT_TRANSFER: 'Conti per il trasferimento',
  ACCOUNT_TRANSFER_ADD: 'Aggiungi conto per il trasferimento',
  COMPANY_ADDRESS_EDIT: 'Modifica indirizzo aziendale',
  CONTACT: 'Contatto',
  COMPANY_EDIT: 'Modifica azienda',
  COMPANY_DATAS: 'Dati aziendali',
  PROFILE_PICTURE: 'Foto del profilo',
  LOGOTIPO: 'Logo',
  LOGOTIPO_COMPANY: 'Logo del profilo',
  ADDRESS: 'Indirizzo',
  ADDRESS_CREATE: 'Crea indirizzo',
  ADDRESS_EDIT: 'Modifica indirizzo',
  ADDRESSES: 'Indirizzi',
  USER_DISABLE: 'Disabilita profilo',
  COMPANY_DISABLE: 'Disabilita azienda',
  REDEFINE_PASSWORD: 'Ridefinisci password',
  CHANGE_PASSWORD: 'Cambia password',
  PREFERENCES: 'Preferenze',
  SETTINGS: 'Impostazioni',
  LANGUAGE: 'Lingua',
  CARD_ADD: 'Aggiungi carta',
  ADDRESS_REGISTER: 'Registra indirizzo',
  PAYMENTS_SUBSCRIPTIONS: 'Pagamenti e abbonamenti',
  PROFILE_MY_ABOUT: 'Informazioni sul mio profilo',
  PROFILE_DETAILS: 'Dettagli del profilo',
  PROFILE_INFORMATIOS: 'Informazioni del profilo',
  PROFILE_PUBLIC: 'Profilo pubblico',
  PROFILE_MAIN: 'Il conto principale',
  PERMISSIONS_GROUP: 'Gruppi e permessi',
  INVITE_TO_LIST: 'Invita alla lista',
  INVITE_PEOPLE: 'Invita persone',
  PARTICIPATE: 'Partecipa',
  LIST_PARTICIPATE: 'Liste partecipanti',
  DETAILS_ITEM: 'Dettagli dell\'articolo',
  MY_ORDERS: 'I miei ordini',
  RESERVES: 'Prenotazioni',
  RESERVES_MY: 'Le mie riserve',
  RESERVE_DETAILS: 'Dettagli della prenotazione',
  SEARCH: 'Ricerca',
  OTHER_INFORMATIONS: 'Altre informazioni',
  TERMS_POLICY: 'Termini e politiche',
  LICENCES: 'Licenze',
  KNOW_APP: 'Conosci l\'app',
  PROFILE_UNMANAGE: 'Profilo non gestito',
  PROFILE_MANAGE: 'Profilo gestito',
  SHARE: 'Condividere',
  WALLET: 'Portafoglio',
  WAIT_LISTS: 'Liste d\'attesa',
  WAIT_LISTS_SHORT: 'Lis. attesa',
  NOITAG: 'Noitag',
  INSTALLMENT: 'Rata',
};


export const STATUS: Record<EStatusTranslations, string> = {
  LOADING: 'Caricamento',
  WAIT: 'Aspettare',
  WAITING: 'In attesa',
  CANCELED: 'Annullato',
  FINISHED: 'Finito',
  SUPER_PARTNER: 'Super socio',
  UPDATING: 'Aggiornamento',
  UPDATING_IN: 'Aggiornamento in',
};


export const SUBTITLES: Record<ESubtitlesTranslations, string> = {
  SUBTITLES_LOGOTIPO_COMPANY: 'Il logo aiuta a personalizzare la tua azienda',
  SUBTITLES_PROFILE_PICTURE: 'Una foto aiuta a personalizzare il tuo profilo',
  SUBTITLES_PROFILE_ALL: 'Guarda come compaiono i tuoi diversi profili nei servizi di Noitada',
  SUBTITLES_INFORMATIONS_COMPANY: 'Nome, informazioni e altro',
  SUBTITLES_DATAS_COMPANY: 'Iscrizione e nome giuridico, per esempio',
  SUBTITLES_COMPANY_CONTACT: 'Clicca per modificare i tuoi contatti',
  SUBTITLES_COMPANY_TRANSFER_YES: 'Clicca per gestire i conti di trasferimento di questa azienda',
  SUBTITLES_COMPANY_TRANSFER_NO: 'I conti di trasferimento non sono ancora disponibili',
  SUBTITLES_COMPANY_ADDRESS: 'Clicca per modificare l\'indirizzo dell\'azienda',
  SUBTITLES_ACCOUNT_TRANSFER_ADD: 'Noitada deve ancora esaminare la tua registrazione. Attendi un po\' prima di riprovare!',
  SUBTITLES_SETTINGS: 'Impostazioni del tuo account globale',
  SUBTITLES_CARDS: 'Carte di credito', // 'Carte di credito e debito',
  SUBTITLES_PROFILE_PUBLIC: 'Queste sono le informazioni che appaiono in questo profilo, che è pubblico',
  SUBTITLES_PERMISSIONS_GROUP: 'Aggiungi persone e gestisci le autorizzazioni',
  SUBTITLES_HIGHLIGHT_MAP: 'Permetti l\'utilizzo della posizione per vedere luoghi nelle vicinanze',
  SUBTITLES_HIGHLIGHT_MAP_EXPERIENCE: 'In questo modo possiamo migliorare la tua esperienza su Noitada',
  SUBTITLES_LIST_IN: 'Sei nella lista',
  SUBTITLES_LIST_INSIDE: 'Sei già nella lista: {listName}',
  SUBTITLES_LIST_OUTSIDE: 'Fai clic sul pulsante partecipa per entrare nella lista: {listName}',
  SUBTITLES_SEE_ITEMS_MY_LOCATION: 'Vedi eventi e partner nelle vicinanze',
  SUBTITLES_KNOW_APP: 'Sconti, promozioni, menù, prenotazioni, ordini, linkbio, biglietti, listini e molto altro.',
  SUBTITLES_WAIT_LISTS_USER_POSITION: 'La tua posizione attuale nella lista d\'attesa',
  SUBTITLES_WAIT_LISTS_PRIORITY: 'In caso di priorità, cercare una persona responsabile sul posto',
  SUBTITLES_WAIT_LISTS_EXIT_ON: 'Puoi partire in qualsiasi momento, ma se esci e vuoi tornare, andrai in fondo alla fila. È una lista d\'attesa, giusto?',
  SUBTITLES_WAIT_LISTS_EXIT_OFF: 'Se desideri lasciare la lista, chiedi al tuo partner di rimuoverti dalla lista d\'attesa.',
};


export const VALIDATIONS: Record<EValidationsTranslations, string> = {
  ONLY_NUMBERS: 'Solo numeri',
  VALIDATE_MIN_DIGITS: 'Deve contenere almeno {count} cifre',
  VALIDATE_MAX_DIGITS: 'Deve contenere al massimo {count} cifre',
  VALIDATE_EXACT_DIGITS: 'Deve contenere esattamente {count} cifre',
  VALIDATE_MIN_CHAR: 'Deve contenere almeno {count} caratteri',
  VALIDATE_MAX_CHAR: 'Deve contenere al massimo {count} caratteri',
  VALIDATE_EXACT_CHAR: 'Deve contenere esattamente {count} caratteri',
  VALIDATE_ALL_FIELDS: 'Compila correttamente tutti i campi prima di continuare',
  VALIDATE_ALL_SELECTED: 'Verifica se tutti i campi sono selezionati',
  VALIDATE_HAS_USER: 'Esiste già un profilo con questo utente/email',
  VALIDATE_ONLY_ONE: 'Seleziona solo uno',
  VALIDATE_ONE_MORE: 'Seleziona uno o più elementi',
  VALIDATE_EMAIL_REQUIRED: 'Il campo e-mail è obbligatorio',
  VALIDATE_EMAIL_VALID: 'Inserisci un indirizzo e-mail valido',
  VALIDATE_EMAIL_DOMAIN: 'Dominio e-mail incompleto',
  VALIDATE_PASSWORD_REQUIRED: 'Il campo password è obbligatorio',
  VALIDATE_ID_SHORT_REQUIRED: 'Il campo ID pubblico è obbligatorio',
  VALIDATE_CPF_VALID: 'Inserisci un CPF valido',
  VALIDATE_USER_CPF_USERNAME: 'Inserisci un CPF valido o un utente con solo lettere, numeri, "_" e "."',
  VALIDATE_USERNAME_CHAR_SPECIAL: 'L\'utente può contenere solo lettere, numeri, "_" e "."',
  VALIDATE_USER_REQUIRED: 'Il campo utente è obbligatorio',
};



const Translates = {
  ...APP,
  ...APP_TUTORIAL,
  ...BUTTONS,
  ...COMPANY_TYPES,
  ...CARNIVALS,
  ...COUNTRIES,
  ...DRINKS,
  ...EVENTS,
  ...INTERPOLATES,
  ...KITCHENS,
  ...LABELS,
  ...LANGUAGES,
  ...MENU_ORDER,
  ...MESSAGES_EMPTYS,
  ...MESSAGES_HELP,
  ...MESSAGES_ERRORS,
  ...MESSAGES_QUESTIONS,
  ...MESSAGES_SUCCESS,
  ...MUSICS,
  ...PAYMENTS,
  ...RESERVES,
  ...SOCIALS,
  ...TIME,
  ...TITLES,
  ...STATUS,
  ...SUBTITLES,
  ...VALIDATIONS,
};



export default Translates;
