import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetHelpFaq,
} from '@noitada/axios-config/endpoints/help.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_MENU_SELECTED_ACTION,
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  EAppHelpNames,
  EDrawerMenu,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '@noitada/shared/utils/errors.utils';

import {
  Content,
  Container,
  FAB,
  Screen,
  Title,
  ToolbarGroup,
} from '../../../components/Composh/web';

import {
  ButtonDrawer,
  ButtonMore,
  // ButtonRequests,
} from '../../../components/Controls';

import EmptyContent from '../../../components/Empty';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconChat,
} from '../../../components/Icons';

import LoadingScreen from '../../../components/Loadings/LoadingScreen';

import {
  openHelpWhatsApp,
} from '../../../config/linking.config';

import NameRoutes from '../../../navigation/names';

import {
  ContentPadder,
} from '../../../styles/styled.layout';

import {
  FaqSectionContainer,
  FaqSectionTitle,
  FaqCategorySectionTitle,
  FaqCategoryButton,
  FaqCategoryView,
  FaqCategoryTitle,
  FaqCategoryDescription,
  FaqCategoryBullet,
  FaqCategoriesContainer,
} from './styled';



const HelpFaqScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);
  // const faq = useSelector((state: RootState) => state.company.allCompanies);

  const [faq, setFaq] = useState<Array<any>>([]);
  const [frequency, setFrequency] = useState<Array<any>>([]);

  const [loading, setLoading] = useState<boolean>(false);
  // const [refreshing, setRefreshing] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getFaq() {
    setLoading(true);

    try {
      const response = await GetHelpFaq(EAppHelpNames.NOITADA_APP);
      const responseModele = response?.data;

      if (!responseModele) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, translate('Sem dados a mostrar!'));
        return;
      }

      const frequencyResponse: Array<any> = [];
      const categoriesResponse: Array<any> = [];

      responseModele?.forEach((item: any) => {
        if (item?.bullets?.length > 0) {
          categoriesResponse.push(item);

          item?.bullets?.forEach((bulletItem: any) => {
            if (bulletItem?.frequent) {
              frequencyResponse.push(bulletItem);
            }
          });
        }
      });

      setFrequency(frequencyResponse);
      setFaq(categoriesResponse);
    }
    catch (error: any) {
      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  }


  function renderSection(title: string) {
    return (

      <FaqCategorySectionTitle>
        {title}
      </FaqCategorySectionTitle>

    );
  }


  function renderBullet(item: any, index: number) {
    return (

      <FaqCategoryView
        key={index}
        onClick={() => {
          history.push({
            pathname: NameRoutes.FaqBulletScreen,
            state: {
              bullet: item,
              category: item?.category?.title,
            },
          });
        }}>

        <FaqCategoryBullet
          color={Colors.buttonOkLight}>
          {item?.title}
        </FaqCategoryBullet>

      </FaqCategoryView>

    );
  }


  function renderItem(item: any, index: number) {
    return (

      <FaqCategoryButton
        key={index}
        // activeOpacity={Sizes.ButtonActiveOpacity}
        onClick={() => {
          history.push({
            pathname: NameRoutes.FaqCategoryScreen,
            state: {
              categories: item,
            },
          });
        }}>

        <FaqCategoryTitle
          color={Colors.buttonOkLight}>
          {item.title}
        </FaqCategoryTitle>


        <FaqCategoryDescription
          color={Colors.black}>
          {item.subtitle}
        </FaqCategoryDescription>

      </FaqCategoryButton>

    );
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noHelp}
        title={translate('Não há FAQs aqui, ainda!')}
        description={translate('Mas, qualquer dúvida entre em contato!')}
      />

    );
  }



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.HELP });
    getFaq();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${translate(TranslateConfig.HELP)} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="noindex, nofollow" />
      </Helmet>



      <Container>

        <HeaderScreen
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonDrawer
              color={Colors.white}
            />
          }
          centerContent={
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.HELP)}
            </Title>
          }
          rightIcon={
            <ToolbarGroup>

              {/* <ButtonHelpContact
                color={Colors.black}
                onPress={() => {
                  // FIXME: Automatizar
                  openHelpWhatsApp();
                }}
              /> */}


              {/*
              <ButtonRequests
                color={Colors.black}
              />
              */}

              <ButtonMore
                color={Colors.white}
              />

            </ToolbarGroup>
          }
        />



        <Content>

          {loading && (
            renderLoading()
          )}



          {!loading && faq?.length === 0 && (
            listEmptyComponent()
          )}



          {!loading && (frequency?.length > 0 || faq?.length > 0) && (
            <ContentPadder>

              <FaqSectionContainer>

                <FaqSectionTitle>
                  {`${translate('Olá')} ${user?.name.split(' ')[0]},`}
                </FaqSectionTitle>


                <FaqCategoryDescription
                  color={Colors.black}>
                  {translate('Aqui você tira todas as suas dúvidas sobre o noitada da Noitada. Precisa de ajuda? Estamos aqui!')}
                </FaqCategoryDescription>

              </FaqSectionContainer>



              {frequency?.length > 0 && (
                <FaqCategoriesContainer>
                  {renderSection(translate('Perguntas frequentes'))}

                  {frequency.map((item, index: number) => (
                    renderBullet(item, index)
                  ))}
                </FaqCategoriesContainer>
              )}



              {faq?.length > 0 && (
                <FaqCategoriesContainer>
                  {renderSection(translate('Categorias'))}

                  {faq?.map((item, index: number) => (
                    renderItem(item, index)
                  ))}
                </FaqCategoriesContainer>
              )}

            </ContentPadder>
          )}

        </Content>

      </Container>



      <FAB
        visible={true}
        position={'right'}
        content={
          <IconChat
            color={Colors.white}
          />
        }
        color={[
          Colors.buttonOk,
          Colors.buttonOkDark,
        ]}
        gradientLinear={'linear'}
        overlayBackground={Colors.alertOverlay}
        onPress={() => {
          openHelpWhatsApp();
        }}
      />

    </Screen>

  );
};



export default HelpFaqScreen;
